import intlTelInput from "intl-tel-input";

function phoneNumberInput(inputId, submitBtn, enableWhenSubmit = false) {
    const input = document.querySelector(`#${inputId}`);

    if (input && submitBtn) {
        const iti = intlTelInput(input, {
            initialCountry: "auto",
            strictMode: true,
            separateDialCode: true,
            placeholderNumberType: 'MOBILE',
            nationalMode: true,
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/22.0.2/js/utils.min.js",
            geoIpLookup: callback => {
                fetch("https://ipapi.co/json")
                    .then(res => res.json())
                    .then(data => callback(data.country_code))
                    .catch(() => callback("us"));
            },
        });

        const handleChange = () => {
            if (input.value) {
                if (iti.isValidNumber()) {
                    input.classList.remove("is-invalid");
                    input.classList.add("is-valid")
                    // input.classList.add("mb-2")
                } else {
                    input.classList.remove("is-valid");
                    // input.classList.remove("mb-2")
                    input.classList.add("is-invalid")
                }
            } else {
                input.classList.remove("is-valid", "is-invalid", "mb-2");
            }
        };

        // listen to "keyup", but also "change" to update when the user selects a country
        input.addEventListener('change', handleChange);
        input.addEventListener('keyup', handleChange);

        submitBtn.addEventListener('click', () => {
            if (iti.isValidNumber()) input.value = iti.getNumber();
            if (enableWhenSubmit) {
                input.disabled = false;
            }
        });

        return iti;
    }
}

export default phoneNumberInput;